import { graphql, useStaticQuery } from "gatsby";
import classNames from 'classnames';

import { GoogleMapsPin, WhatsApp, Email, Shop } from '@elements/Icon';

const profiles = [
	{
		label: 'הנגריה בחנות סמרה',
		title: 'מעבר לחנות הדיגיטלית',
		url: 'https://www.samara-shop.co.il/%D7%A0%D7%92%D7%A8%D7%99%D7%99%D7%AA%D7%91%D7%95%D7%98%D7%99%D7%A7',
		Icon: Shop
	},
	{
		label: 'ניווט לנגריה',
		title: 'ניווט לנגריה עם גוגל מפות',
		url: 'https://goo.gl/maps/W1g62qjyWX28MPg99',
		Icon: GoogleMapsPin
	},
	{
		label: 'שליחת הודעה',
		title: 'יצירת קשר עם הנגרייה בוואטספ',
		url: 'https://api.whatsapp.com/send?phone=972524619161',
		Icon: WhatsApp
	},
	{
		label: 'שליחת מייל',
		title: 'יצירת קשר עם הנגריה במייל',
		url: 'mailto:',
		Icon: Email
	}
];

export default function Contact({ iconsOnly, isCenterAligned, className: classes }) {

	const { site: { siteMetadata: { emailAddress } } } = useStaticQuery(graphql`
      query {
				site {
					siteMetadata {
						emailAddress
					}
				}
			}
  `);

	const className = classNames('is-flex buttons', isCenterAligned && 'is-centered', classes);
	return <div {...{ className }}>
		{profiles.map(({ title, url, Icon, label }) => {
			if (url === 'mailto:') url += emailAddress;
			return <a className="button has-text-primary-dark" key={url} href={url} title={title} target='_blank' rel='noopener noreferrer'>
				<Icon />
				{!iconsOnly && <span className="ml-2">{label}</span>}
			</a>;
		})}
	</div>;
}