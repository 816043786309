import Contact from "@elements/Contact";

export default function Footer() {
	return <footer className="is-flex is-flex-direction-column is-align-items-center mt-6 reset-anchors">
		<Contact iconsOnly={true} />
		<span className="has-text-white">כל הזכויות שמורות ל
			<a href="https://kibbutz-samar.com/he" className="has-text-white" target="_blank" rel="noopener noreferrer">קיבוץ סמר</a>
			<span> © {new Date().getFullYear()}</span>
		</span>
	</footer>;
}