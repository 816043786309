import { useState, useRef, useCallback, useContext, Fragment } from 'react';
import classNames from 'classnames';

import { CartContext } from './index';

const formName = 'cart-form';

export default function Form() {

	const { cart } = useContext(CartContext);

	const ref = useRef(null);
	const [validation, setValidation] = useState(false);
	const [succeeded, setSucceeded] = useState(false);

	const resetValidation = useCallback(() => {
		setValidation(false);
		setSucceeded(false);
	}, [setValidation]);

	const handleSubmit = useCallback((event) => {
		event.preventDefault();

		const elements = ref.current.elements;

		if (!elements['טלפון או כתובת מייל ליצירת קשר'].value) return setValidation(true);

		const formElem = ref.current;
		const formData = new FormData(formElem);

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(formData).toString(),
		}).then(() => setSucceeded(true));

	}, [ref, setValidation, setSucceeded]);

	const inputClassName = classNames('input mb-3', validation && 'is-danger');

	const inventory = cart.length > 0 ? cart : Array.from({ length: 10 }, (_, i) => ({ id: i, title: '', options: '' }));

	return <form name={formName} method="POST" ref={ref} netlify="true" className='mb-3'>
		<input type="hidden" name="form-name" value={formName} />
		<input onChange={resetValidation} className={inputClassName} placeholder='טלפון או כתובת מייל ליצירת קשר' type="text" name="טלפון או כתובת מייל ליצירת קשר" />

		{inventory.map(({ id, title, options }, i) => {
			const index = (i + 1 + '').padStart(2, 0);
			const optionsStr = Object.entries(options).map(([key, value]) => `${key}: ${value}`).join(', ');
			return <Fragment key={id + title}>
				<input type="hidden" name={`שם מוצר ${index}`} value={title} />
				<input type="hidden" name={`אפשרויות מוצר ${index}`} value={optionsStr} />
			</Fragment>;
		})}

		{succeeded
			? <div className="notification has-text-weight-bold has-text-centered p-3 is-success is-size-5">אחלה! נהיה בקשר</div>
			: <button onClick={handleSubmit} className='button is-medium has-text-weight-bold is-fullwidth' style={{ border: '3px solid #9dcb00', color: '#708f06' }}>אשמח להצעת מחיר</button>}
	</form>;
}