import { createElement } from "react";

import Footer from "@elements/Footer";

import CartProvider from "./Cart";

export default function PageWrapper({ element, props }) {
	return createElement(
		Wrapper,
		props,
		element
	);
}

function Wrapper({ children }) {
	return <CartProvider>
		<div id="site-border" className="box container">
			<main className="box has-background-white">
				{children}
			</main>
			<Footer />
		</div>
	</CartProvider>;
}