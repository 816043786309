import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { Cart } from '@elements/Icon';

import Form from './Form';

const localStorageKey = 'ns-cart';

export const CartContext = createContext();

export default function CartProvider({ children }) {
	return <CartContext.Provider value={useCart()}>
		<CartInventory />
		{children}
	</CartContext.Provider>;
}

function CartInventory() {
	const { cart, removeFromCart } = useContext(CartContext);

	const [isOpen, setIsCartOpen] = useState(false);
	const toggleCart = useCallback(() => {
		// document.body.classList.toggle("with-cart", !isOpen);
		setIsCartOpen(!isOpen);
	}, [isOpen]);

	const className = classNames('box is-paddingless has-background-primary', cart.length === 0 && 'is-hidden');
	const inventoryClassName = classNames('mx-3', isOpen || 'is-hidden');

	return <div id="ns-cart" className={className} style={{ top: '0', left: '0', borderRadius: '0', overflow: 'auto', maxHeight: '100%', maxWidth: '35rem' }}>

		<div className="is-flex is-flex-direction-row-reverse is-justify-content-space-between is-align-items-center">
			<button className='button is-primary is-large pl-5' onClick={toggleCart}>
				<Cart />
				<span className="icon-text pr-2">{cart.length}</span>
			</button>
			{isOpen && <h2 className='is-size-5 has-text-centered has-text-weight-bold mr-3'>עגלת הקניות</h2>}
		</div>

		<div className={inventoryClassName}>

			{cart.map(({ id, title, slug, shortDesc, image, options }) => {

				return <div key={id} className='has-background-white-ter p-2 mb-3' style={{ borderRadius: '.25rem' }}>
					<div className='is-flex reset-anchors'>
						<a href={`/product/${slug}`} className='is-flex-shrink-1'>
							<img src={image} alt={title} className='is-block' style={{ width: '5rem', height: '5rem', objectFit: 'cover', borderRadius: '.5rem' }} />
						</a>
						<div className='px-3'>
							<a href={`/product/${slug}`}><h3 className='is-size-5 has-text-weight-bold mb-1'>{title}</h3></a>
							<p className='is-size-6'>{shortDesc}</p>
							<div className='tags mt-2'>
								{Object.entries(options).map(([key, value]) => <span key={key} className='tag has-background-grey-lighter has-text-black'>{key}: {value}</span>)}
							</div>
						</div>
						<div className='is-flex-shrink-1'>
							<button className='delete' aria-label='הסרה מעגלת הקניות' title="הסרה מעגלת הקניות" onClick={() => removeFromCart(id)} />
						</div>
					</div>
				</div>;
			})}

			<Form />

		</div>
	</div>;
}

function useCart() {
	const [cart, setCart] = useState([]);
	useEffect(() => {
		setCart(getCartFromLocalStorage());
	}, []);

	const addToCart = (item) => {
		const updatedCart = cart.concat(item);
		updateCart(updatedCart);
	};

	const removeFromCart = (id) => {
		const updatedCart = cart.filter((item) => item.id !== id);
		updateCart(updatedCart);
	};

	const updateCart = (updatedCart) => {
		setCart(updatedCart);
		localStorage.setItem(localStorageKey, JSON.stringify(updatedCart));
	}

	return {
		cart,
		addToCart,
		removeFromCart,
	};
}

function getCartFromLocalStorage() {
	const cart = localStorage.getItem(localStorageKey);
	return cart ? JSON.parse(cart) : [];
}